up.compiler('[update-addresses-button]', (element) => {
  element.addEventListener('click', () => {
    const form = document.querySelector('.receipt-form')
    const oldAddresses = form.querySelector('.addresses-form')
    const newAddresses = element.closest('up-modal-content').querySelector('.addresses-form')

    oldAddresses.replaceWith(newAddresses)

    up.validate(form, { 'up-layer': 'parent'})
    up.layer.dismiss()
  })
})
