import tours from '../modules/tours'

tours.add('app', [
  {
    title: 'Hallo!',
    text: `
      <p>Willkommen zur LVW-Sachsen.app!</p>
      <p>Wir möchten Ihnen eine kurze Einführung in die Anwendung geben.</p>
      <p>Bitte klicken Sie auf den „Weiter“-Button.</p>
    `,
  },
  {
    attachTo: {
      element: '.navigation',
    },
    title: 'Hauptmenü',
    text: `
      <p>Am oberen Bildschirmrand befindet sich die Hauptnavigation.</p>
      <p>Hier wechseln Sie zwischen den verschiedenen Bereichen der Anwendung.</p>
      <p>Einige Menüpunkte, z.B. „Verkehrssicherheitstage“, öffnen ein Untermenü.</p>
    `,
  },
  {
    attachTo: {
      element: '.content',
      on: 'top',
    },
    title: 'Inhaltsbereich',
    text: `
      <p>Die Inhalte des gewählten Abschnitts werden unterhalb der Navigation angezeigt.</p>
      <p>Hier werden Daten angezeigt, oder können bearbeitet werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.current-user-link',
    },
    title: 'Benutzerprofil',
    text: `
      <p>Über dieses Element öffnen Sie das Benutzermenü.</p>
      <p>Dort können Sie Ihr Profil bearbeiten oder sich vom System abmelden.</p>
    `,
  },
  {
    attachTo: {
      element: '.edit-moderator-link',
    },
    title: 'Moderatoren-Stammdaten',
    text: `
      <p>Über diesen Menüpunkt bearbeiten Sie die Stammdaten, die für Sie als Moderator hinterlegt sind.</p>
    `,
    when: {
      show() {
        tours.openNavigationFor(this.getTarget())
      },
    },
  },
  {
    attachTo: {
      element: '.edit-profile-link',
    },
    title: 'Benutzerprofil',
    text: `
      <p>Über diesen Menüpunkt bearbeiten Sie Ihr Benutzerkonto.</p>
    `,
    when: {
      show() {
        tours.openNavigationFor(this.getTarget())
      },
    },
  },
  {
    attachTo: {
      element: '.sign-out-link',
    },
    title: 'Logout',
    text: `
      <p>Klicken Sie hier, um sich vom System abzumelden.</p>
    `,
    when: {
      show() {
        tours.openNavigationFor(this.getTarget())
      },
    },
  },
  {
    attachTo: {
      element: '.help-button',
      on: 'bottom',
    },
    title: 'Vielen Dank für Ihre Aufmerksamkeit!',
    text: `
      <p>Für die meisten Seiten der Anwendung können Sie eine Anleitung wie diese aufrufen.</p>
      <p>Klick Sie dazu einfach auf den jeweiligen „Hilfe“-Button.</p>
      <p>Diese Kurzeinführung können Sie bei Bedarf auch später über Ihr Benutzermenü aufrufen. Klick Sie dort auf „Einführung anzeigen“.</p>
      <p>Viel Spaß mit der LVW-Sachsen-App!</p>
    `,
    orphan: true,
  },
], false)
