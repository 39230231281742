export const FileuploadHelper = {

  fail: function (error) {
    console.error('[image file upload]', error)
  },

  preview: function (file, img) {
    const blobURL = URL.createObjectURL(file)
    img.removeAttribute('hidden')
    img.setAttribute('src', blobURL)
  },

  setCacheName: function (cacheName, element) {
    const cacheField = element.querySelector('input[type=hidden].gallery-form--file-cache-input')
    cacheField.value = cacheName
  },
}
