import { FileuploadHelper } from '../modules/fileupload_helper'

up.compiler('[gallery-form]', (element, [uploadURL]) => {
  const galleryFormFields = element.querySelector('.gallery-form--fields')
  const imageTemplate = element.querySelector('.gallery-form--template')
  const fileField = element.querySelector('input[type=file]')

  up.on(fileField, 'change', (event) => {
    addImages(event, fileField.files)
  })
  up.on(element, 'click', '.gallery-form--remove', removeImage)

  function addImages(event, imageFiles) {
    for(let i = 0; i < imageFiles.length; ++i) {
      const file = imageFiles[i]

      if (!file.name.match(/(.jpg|.JPG|.jpeg|.JPEG|.png|.PNG)$/)) {
        alert("\"" + file.name + "\" ist ungültig. Bitte wählen Sie ein Foto im Format 'jpg', 'jpeg' oder 'png'.")
        continue
      }

      let image = NestedFormHelper.addImage()

      image.querySelector('.gallery-form--title-input').removeAttribute('hidden')
      FileuploadHelper.preview(file, image.querySelector('img'))

      const params = { image: file }

      const request = up.request(uploadURL, { params, method: 'PUT' })
        .then((response) => {
          FileuploadHelper.setCacheName(JSON.parse(response.text), image)
          up.emit(image, 'content:updated')
          fileField.value = ''
        })
        .catch(FileuploadHelper.fail)

      // Cancel upload when removing an image
      up.on(image, 'click', '.gallery-form--remove', () => {
        request.abort()
      })
    }
  }

  function removeImage(event) {
    event.preventDefault()

    const image = event.target.closest('.gallery-form--image')
    const destroyField = image.querySelector('.gallery-form--destroy-input')

    image.classList.add('hidden')
    destroyField.value = 'true'
    up.emit(image, 'content:updated')
  }

  const NestedFormHelper = {
    addImage() {
      const image = imageTemplate.cloneNode(true)

      NestedFormHelper._bumpTemplate()

      image.classList.remove('gallery-form--template')
      image.classList.remove('hidden')
      image.querySelector('.gallery-form--remove').classList.remove('-hidden')
      image.querySelector('.gallery-form--destroy-input').value = false
      image.classList.add('gallery-form--image')

      return galleryFormFields.insertBefore(image, imageTemplate)
    },

    _bumpTemplate() {
      imageTemplate.querySelectorAll('input').forEach(function(input) {
        NestedFormHelper._bump(input, 'name', /\[(\d+)\]/)
        NestedFormHelper._bump(input, 'id', /_(\d+)_/)
      })

      NestedFormHelper._bump(imageTemplate.querySelector('input[id$=position]'), 'value')
    },

    _bump(element, attribute, pattern) {
      if (pattern == null) {
        pattern = /(\d+)/
      }

      const oldValue = element.getAttribute(attribute)
      const newValue = oldValue.replace(pattern, function(match, firstMatch) {
        return match.replace(firstMatch, parseInt(firstMatch) + 1)
      })

      element.setAttribute(attribute, newValue)
    }
  }
})
