import tours from '../modules/tours'

tours.add('exports', [
  {
    title: 'Exporte',
    text: `
      <p>Sie befinden sich im Exportbereich.</p>
      <p>Dieser umfasst Excel-Downloads für Daten, die nicht zum Bereich „Auswertungen“ gehören.</p>
      <p>Exporte können bei Bedarf heruntergeladen und nach eigenen Kriterien in Anwendungen wie MS Excel ausgewertet werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.master-data-exports',
      on: 'top',
    },
    title: 'Stammdaten-Exporte',
    text: `
      <p>Hier finden Sie Exporte von im System gespeicherten Stammdaten.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Stammdaten-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.directly-billable-exports',
      on: 'top',
    },
    title: 'DAP-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Direktabrechnungen.</p>
      <p>Bitte beachten Sie, dass die Erstellung von DAP-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.road-safety-day-exports',
      on: 'top',
    },
    title: 'Verkehrssicherheitstag-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Verkehrssicherheitstagen.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Verkehrssicherheitstag-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.moderated-event-exports',
      on: 'top',
    },
    title: 'Moderationsveranstaltungs-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Moderationsveranstaltungen.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Moderationsveranstaltungs-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.budget-exports',
      on: 'top',
    },
    title: 'Budget-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Budgets.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Budget-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.inventory-exports',
      on: 'top',
    },
    title: 'Inventar-Exporte',
    text: `
      <p>Hier finden Sie Exporte zum Inventar.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Inventar-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.bulk-exports',
    },
    title: 'Sachberichte',
    text: `
      <p>Sachberichte können Sie, gegliedert nach Projekt, in diesem Bereich herunterladen.</p>
    `,
  },
  {
    attachTo: {
      element: '.newsletter-exports',
      on: 'top',
    },
    title: 'Newsletter',
    text: `
      <p>Hier können Sie Listen mit E-Mail-Adressen aller Newsletter-Abonnenten herunterladen.</p>
    `,
  },
])
