up.compiler('[advanced-confirmation-modal]', (button, data) => {
  const url = data['url']
  let confirmed = false

  function onClick(event) {
    if (!confirmed) {
      requestConfirmation()
      event.preventDefault()
    }
  }

  function requestConfirmation() {
    up.layer.open({url, history: false, onAccepted: onAccepted})
  }

  function onAccepted() {
    confirmed = true
    button.focus() // focus required for form param to be submitted (because of how Unpoly works)
    button.click()
  }

  button.addEventListener('click', onClick)
})
