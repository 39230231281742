up.compiler('input[type=file]', (element) => {

  element.addEventListener('change', function() {
    const filename = String(element.value)
    const basename = filename.replace(/^.*[\\\/]([^\\\/]+)$/, '$1')
    const customFile = element.closest('.custom-file')

    if (customFile) {
      customFile.querySelector('.custom-file-label').innerText = basename
    }
  })

})
