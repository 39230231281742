up.compiler('.date-group-with-overlap-check', (element) => {

  let form = element.closest('form')

  function updateDateGroup() {
    up.validate('[time-picker]', { target: '.date-group-with-overlap-check, .overlap-notice' })
  }

  up.on(form, 'blur', '[time-picker]', updateDateGroup)

  return function () {
    up.off(form, 'blur', '[time-picker]', updateDateGroup)
  }

})
