import tours from '../modules/tours'

tours.add('project_memos', [
  {
    title: 'Downloads',
    text: `
      <p>Hier können Sie Hinweise zu den unterschiedlichen Projekten abrufen.</p>
      <p>Diese beschreiben verschiedenste Themen, z.B. zur Durchführung oder Abrechnung von Veranstaltungen.</p>
    `,
  },
  {
    attachTo: {
      element: '.project-memos',
    },
    title: 'Downloads',
    text: `
      <p>Eine Liste der herunterladbaren Dokumente wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann der entsprechende Projekthinweis angezeigt werden.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Filter',
    text: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Hinweis',
    text: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])
