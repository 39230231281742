up.compiler('[expense]', (element) => {
  let used = element.querySelector('input[type="checkbox"][name$="[used]"]')
  let noCosts = element.querySelector('input[type="checkbox"][name$="[no_costs]"]')
  let requestedAmount = element.querySelector('input:not(:disabled)[name$="[requested_amount]"]')
  let lvwApprovedAmount = element.querySelector('input:not(:disabled)[name$="[approved_lvw_amount]"]')
  let inventory = element.querySelector('select:not(:disabled)[name$="[inventory_id]"]')
  let affiliation = element.querySelector('select:not(:disabled)[name$="[affiliation]"]')

  function onUsedChange() {
    updateDisabled()

    if (isUsed()) {
      requestedAmount.focus()
    } else {
      noCosts.checked = false
      if (requestedAmount)
        requestedAmount.value = ''
      if (inventory) {
        inventory.value = ''
        up.emit(inventory, 'change')
      }
      up.emit(requestedAmount, 'change')
    }
  }

  function onNoCostsChange() {
    updateDisabled()
    if (!requestedAmount)
      return

    if (isNoCosts()) {
      requestedAmount.value = '0.00'
      up.emit(requestedAmount, 'change')
    } else {
      requestedAmount.value = ''
      requestedAmount.focus()
    }
  }

  function onAmountChange() {
    let value = requestedAmount?.value
    if (value !== '' && value == 0 && !isNoCosts()) {
      noCosts.checked = true
      up.emit(noCosts, 'change')
    }

    updateDisabled()
  }

  function isUsed() {
    return used.checked
  }

  function isNoCosts() {
    return noCosts.checked
  }

  function updateDisabled() {
    let used = isUsed()
    let hasNoCosts = isNoCosts()

    noCosts.parentElement.querySelector('label').classList.toggle('text-muted', !used)
    noCosts.disabled = !used
    if (inventory)
      inventory.disabled = !used
    if (requestedAmount)
      requestedAmount.disabled = !used || hasNoCosts
    if (lvwApprovedAmount)
      lvwApprovedAmount.disabled = !used || hasNoCosts
    if (affiliation)
      affiliation.disabled = !used
  }

  if (requestedAmount || lvwApprovedAmount) {
    updateDisabled()

    up.on(used, 'change', onUsedChange)
    up.on(noCosts, 'change', onNoCostsChange)
    up.on(requestedAmount, 'change', onAmountChange)

    return function() {
      up.off(used, 'change', onUsedChange)
      up.off(noCosts, 'change', onNoCostsChange)
      up.off(requestedAmount, 'change', onAmountChange)
    }
  }
})
