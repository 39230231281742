up.compiler('[datev-export]', (element, data) => {

  let progressUrl = data['progressUrl']
  let updateInterval = data['updateInterval']

  let interval = setInterval(fetchProgress, updateInterval)

  function fetchProgress() {
    fetch(progressUrl).then(update)
  }

  function update(result) {
    if (result === 'finished' || result === 'failed') {
      up.reload('[datev-export-link]')
      up.reload('[datev-export-progress-banner]')
      clearInterval(interval)
    }
  }

  return function() {
    clearInterval(interval)
  }

})
