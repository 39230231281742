up.compiler('[expense-with-inventory]', (element, data) => {

  const VISIBLE_CLASS = '-visible'

  let inventoryContainer = element.querySelector('.selected-inventory')
  if (!inventoryContainer) return

  let inventorySelect = inventoryContainer?.querySelector('select')

  let urlTemplate = data['urlTemplate']
  let expenseRowClass = data['rowClass']
  let inventoryInfoButton = element.querySelector('[inventory-info]')
  let expenseRow = document.querySelector(`.${expenseRowClass}`)
  let affiliationSelect = expenseRow.querySelector('[inventory-affiliation]')


  function getInventoryId() {
    return inventorySelect.value
  }

  function getAffiliation() {
    return affiliationSelect.value
  }

  function toggleInventoryInfoButtonDisabled() {
    inventoryInfoButton.disabled = !getInventoryId()
  }

  function onClickInventoryInfoButton() {
    let url = urlTemplate.replace('_ID_', getInventoryId())
    up.layer.open({ url, history: false })
  }

  function onAffiliationSelectChange() {
    setVisibilityOfInventoryContainer()
    inventorySelect.value = null
    up.emit(inventorySelect, 'change')
    up.validate(expenseRow)
  }

  function setVisibilityOfInventoryContainer() {
    switch(getAffiliation()){
      case 'own':
      case 'foreign':
        inventoryContainer.classList.add(VISIBLE_CLASS)
        break
      case 'none':
        inventoryContainer.classList.remove(VISIBLE_CLASS)
        break
      default:
        inventoryContainer.classList.remove(VISIBLE_CLASS)
    }
  }

  inventorySelect.addEventListener('change', toggleInventoryInfoButtonDisabled)
  inventoryInfoButton.addEventListener('click', onClickInventoryInfoButton)
  affiliationSelect.addEventListener('change', onAffiliationSelectChange)

  setVisibilityOfInventoryContainer()

  if (!getInventoryId()) {
    toggleInventoryInfoButtonDisabled()
  }

})
