import TomSelect from "tom-select/dist/js/tom-select.base"
import change_listener from 'tom-select/dist/js/plugins/change_listener'
import remove_button from 'tom-select/dist/js/plugins/remove_button'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";

TomSelect.define('change_listener', change_listener)
TomSelect.define('remove_button', remove_button)

up.compiler('select', (element, customizations) => {

  if (element.closest('[no-edit]')) {
    return
  }

  const defaultOptions = {
    create: false,

    searchField: ['text', 'searchTags'],
    render: {
      create: (data, escape) => { return `<div class="create><strong>${escape(data.input)}</strong></div>` },
      no_results: () => { return '<div class="no-results">Keine Übereinstimmungen gefunden</div>' },
    },
    plugins: {
      change_listener: {},
      remove_button: {
        append: element.matches('[multiple]'),
        title: "Dieses Element entfernen"
      },
    },
    onInitialize() {
      this.dropdown.style.margin = '0' // needed to remove popper.js warnings due to margin in the browser console
      this.popper = createPopper(this.control, this.dropdown, {
        modifiers: [flip, preventOverflow],
      })
    },
  }

  if (up.layer.isOverlay()) {
    defaultOptions.dropdownParent = element.closest('up-modal-viewport')
  }

  const options = Object.assign({}, defaultOptions, customizations)

  const tomSelect = new TomSelect(element, options)

  tomSelect.positionDropdown = function() {
    this.popper.update()
    this.dropdown.style.width = `${this.control.getBoundingClientRect().width}px`
  }

  tomSelect.on('destroy', function() {
    this.popper.destroy()
  })

  element.tomSelect = tomSelect

  // Clean up when the element gets removed from the DOM.
  return () => {
    tomSelect.destroy()
  }

})
