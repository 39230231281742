import tours from '../modules/tours'

tours.add('reports', [
  {
    title: 'Auswertungen',
    text: `
      <p>Sie befinden sich im Auswertungsbereich.</p>
      <p>Hier bietet Ihnen die Anwendung verschiedenste Statistiken.</p>
    `,
  },
  {
    attachTo: {
      element: '.report',
      on: 'top',
    },
    title: 'Auswertungen',
    text: `
      <p>Auswertungen zeigen Daten immer in tabellarischer Form an, aufgeschlüsselt nach Bundesland und Zeitfenster.</p>
    `,
    scrollTo: false,
  },
  {
    attachTo: {
      element: '.report-form',
      on: 'right',
    },
    title: 'Auswertungen',
    text: `
      <p>In diesem Seitenabschnitt können Sie die anzuzeigende Auswertung konfigurieren.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="columns"]',
      on: 'right',
    },
    title: 'Auswertungen konfigurieren',
    text: `
      <p>Wählen Sie hier die gewünschte zeitliche Gruppierung.</p>
      <p>Bei einer Gruppierung nach Jahren, Quartalen oder Monaten zeigt die Auswertung immer die letzten 12 Vergleichszeiträume mit an.</p>
      <p>Im Falle eines freien Zeitraums wird eine Spalte mit den Werten des gewählten Zeitfensters angezeigt.</p>
    `,
  },
  {
    attachTo: {
      element: '.report-dates',
      on: 'right',
    },
    title: 'Auswertungen konfigurieren',
    text: `
      <p>Die Anwendung wählt automatisch den aktuellsten Betrachtungszeitraum. Wenn gewünscht, können Sie diesen hiermit ändern.</p>
      <p>Bitte beachten Sie: Ein ausgewähltes Datum wird ggf. automatisch korrigert, sodass es am Ende eines Gruppierungszeitraums <span class="text-nowrap">(z.B. Quartal)</span> liegt.</p>
      <p>Bei Wahl eines freien Zeitraums können sie Start- und End-Datum frei bestimmen.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="record_type"]',
      on: 'right',
    },
    title: 'Auswertungen konfigurieren',
    text: `
      <p>Wählen Sie nun die auszuwertende Datenbasis.</p>
      <p>Dies können Verkehrssicherheitstage, Moderationsveranstaltungen, oder Moderatoren sein.</p>
      <p>Die Datenbasis bestimmt, welche Auswertungen („Aggregationen“) wählbar sind.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="project"]',
    },
    title: 'Auswertungen konfigurieren',
    on: 'right',
    text: `
      <p>Sie können die Auswertungsmenge auf einzelne Projekte einschränken.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="state"]',
      on: 'right',
    },
    title: 'Auswertungen konfigurieren',
    text: `
      <p>Außerdem ist eine Einschränkung über den Vorgangsstatus möglich (nicht bei Auswertungen über Moderatoren).</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="aggregation"]',
      on: 'right',
    },
    title: 'Auswertungen konfigurieren',
    text: `
      <p>Wählen Sie schlussendlich die gewünschte Aggregation, um Ihre Auswertung zu bestimmen.</p>
      <p>Abhängig von der gewählten Datenbasis stehen Ihnen in Summe über 40 Auswertungen zur Verfügung.</p>
    `,
  },
])
