up.compiler('.expenses', (element) => {
  const ONE_DAY = 1000 * 60 * 60 * 24

  let form = element.closest('form')

  function durationInDays () {
    let startDate = Date.parse(form.querySelector('input[name$="[starts_at]"]')?.value)
    let endDate = Date.parse(form.querySelector('input[name$="[ends_at]"]')?.value)

    if (startDate && endDate) {
      return 1 + Math.trunc((endDate - startDate) / ONE_DAY)
    } else {
      return undefined
    }
  }

  function maxDays(amounts) {
    let amountsKeysAsIntegerArray = Object.keys(amounts).map(x => parseInt(x))
    return Math.max(...amountsKeysAsIntegerArray)
  }

  function updateMaxAmountHint(row, days) {
    let amounts = JSON.parse(row.dataset.amounts)
    let isExpenseAllowance = JSON.parse(row.dataset.expenseAllowance)
    let maxDaysForAmounts = maxDays(amounts)

    if (days > maxDaysForAmounts) {
      days = maxDaysForAmounts
    } else if (days < 1) {
      days = 1
    }

    let maxAmount = undefined
    for (let i = days; i > 0; i--) {
      if (maxAmount === undefined && amounts[i] != "") {
        if (isExpenseAllowance) {
          if (isUsed(row)) {
            maxAmount = amounts[i] * sumOfMaxExpenseAllowancesForUsedNonExpenseAllowances()
          } else {
            maxAmount = undefined
          }
        } else {
          maxAmount = amounts[i]
        }
        const hint = row.querySelector('.max-amount-hint')
        hint.innerText = "Maximalbetrag: " + (maxAmount || 0) + "€"
        hint.maxAmount = maxAmount
      }
    }
  }

  function updateMaxAmountHints() {
    const days = durationInDays()
    if (typeof days === 'number') {
      let expenseRows = element.querySelectorAll('tr')
      expenseRows.forEach(function (element) {
        updateMaxAmountHint(element, days)
      })
    }
  }

  function isUsed(row) {
    const used = row.querySelector('input[type="checkbox"][name$="[used]"]')
    return used.checked
  }

  function sumOfMaxExpenseAllowancesForUsedNonExpenseAllowances() {
    let sum = 0
    usedNonExpenseAllowances().forEach(function(usedInput, index) {
      const row = usedInput.closest('tr')
      sum += JSON.parse(row.dataset.maxExpenseAllowances)
    })
    return sum
  }

  function usedNonExpenseAllowances() {
    return form.querySelectorAll('[data-expense-allowance="false"] input:checked[type="checkbox"][name$="[used]"]')
  }

  function onUsedChange() {
    const days = durationInDays()
    if (typeof days === 'number') {
      let expenseAllowanceRows = form.querySelectorAll('tr[data-expense-allowance="true"]')
      expenseAllowanceRows.forEach(function (row) {
        updateMaxAmountHint(row, days)
        const requestedAmount = row.querySelector('.expenses--requested-amount')
        up.emit(requestedAmount, 'change')
      })
    }
  }


  updateMaxAmountHints()
  up.on(form, 'change', '[datetime-picker]', updateMaxAmountHints)
  up.on(form, 'change', 'input[type="checkbox"][name$="[used]"]', onUsedChange)

  return () => {
    up.off(form, 'change', '[datetime-picker]', updateMaxAmountHints)
    up.off(form, 'change', 'input[type="checkbox"][name$="[used]"]', onUsedChange)
  }

})
