import tours from '../modules/tours'

tours.add('road_safety_day_request_edit', [
  {
    title: 'Antrag Verkehrssicherheitstag',
    text: `
      <p>Sie befinden sich in der Bearbeitungsmaske eines Verkehrssicherheitstags.</p>
      <p>Hier können Sie den Antrag ändern und im Genehmigungsprozess weitergeben.</p>
    `,
  },
  {
    attachTo: {
      element: 'form[class*="edit"]',
      on: 'top',
    },
    title: 'Bearbeitungsformular',
    text: `
      <p>Im Inhaltsabschnitt können Sie Daten des Antrags bearbeiten.</p>
    `,
    scrollTo: false,
  },
  {
    attachTo: {
      element: '.form-actions [type="submit"]',
      on: 'top',
    },
    title: 'Bearbeitungsformular',
    text: `
      <p>Änderungen werden erst gespeichert, sobald Sie diese über den „Speichern“-Button bestätigen.</p>
    `,
  },
  {
    attachTo: {
      element: '.state-actions-wrapper',
    },
    title: 'Aktionen',
    text: `
      <p>Um den Antrag im Genehmigungsprozess weiterzugeben, stehen Ihnen verschiedene Aktionen zur Verfügung.</p>
      <p>Diese sind abhängig vom aktuellen Antrags-Status und Ihren Benutzerrechten.</p>
      <p>Beim Weitergeben werden Änderungen ebenfalls gespeichert.</p>
    `
  },
])
