import tours from '../modules/tours'

tours.add('road_safety_day_bills', [
  {
    title: 'Abrechnungen Verkehrssicherheitstage',
    text: `
      <p>Sie befinden sich auf der Übersichtsliste der Abrechnungen zu Verkehrssicherheitstagen.</p>
      <p>Abrechnungen werden von Verkehrswachten für durchgeführte Verkehrssicherheitstage eingereicht und durchlaufen einen Prüf- und Genehmigungsprozess.</p>
    `,
  },
  {
    attachTo: {
      element: '.road-safety-day-bills',
    },
    title: 'Abrechnungen Verkehrssicherheitstage',
    text: `
      <p>Eine Liste der Abrechnungen wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann die entsprechende Abrechnung aufgerufen werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.btn[href$="/bills/new"]',
    },
    title: 'Abrechnungen Verkehrssicherheitstage',
    text: `
      <p>Eine neue Abrechnung können Sie über diesen Button erstellen.</p>
      <p>Abrechnungen können nur für zuvor genehmigte Anträge erstellt werden.</p>
      <p>Antragsdaten werden (großteils) automatisch in die Abrechnung übernommen.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Filter',
    text: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_query"]',
    },
    title: 'Filter',
    text: `
      <p>Das Freitext-Suchfeld durchsucht Vorgangsnummer, Veranstaltungsort und Ansprechpartner.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_starts_at"]',
    },
    title: 'Filter',
    text: `
      <p>Benutzen Sie die Datumsfilter, um Abrechnungen anhand des Veranstaltungstages zu suchen.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_states"]',
    },
    title: 'Filter',
    text: `
      <p>Zur weiteren Einschränkung können Kriterien wie Projekt, Status, usw. gewählt werden.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Hinweis',
    text: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])
